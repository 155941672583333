import styled from 'styled-components'
import { rgba } from 'polished'
import { spacing, colors, mq } from '../../styles/utilities/variables'
import StyledDivider from '../Divider/style'
import StyledContent from '../Content/style'
import StyleLoader from '../Loader/style'
import StyledForm from '../Form/style'

const StyledCheckout = styled.div`
  ${StyledDivider} {
    opacity: 0.3;
  }

  ${StyleLoader} {
    margin: 0 ${spacing.base};
  }

  ${StyledForm.Group} {
    margin-bottom: ${spacing.medium};
  }
`

StyledCheckout.Error = styled.div`
  background: ${colors.primaryRed};
  color: ${colors.white};
  padding: ${spacing.medium};
`

export default StyledCheckout
