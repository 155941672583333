import { COLORS, SPACING } from '../../helpers/constants'
import React, { useContext, useState } from 'react'

import { AppContext } from '../../config/context'
import { Button } from '../Button'
import { Cart } from '../Cart'
import { Container } from '../Container'
import { Content } from '../Content'
import { Divider } from '../Divider'
import { Heading } from '../Heading'
import { Loader } from '../Loader'
import { PageContent } from '../PageContent'
import { Row } from '../Row'
import StyledCheckout from './style'
import { Text } from '../Text'
import { renderKlarnaSnippet, getProductsInCart } from '../../helpers/checkout'
import { sendPurchase } from '../../helpers/api'
import { useTranslation } from '../../hooks/useTranslation'

const Checkout = () => {
  const [state] = useContext(AppContext)
  const { products, productsInCart } = state
  const getTranslation = useTranslation()
  const filteredProducts = getProductsInCart(products, productsInCart)

  const [isLoading, setIsLoading] = useState(false)
  const [hasError, setHasError] = useState(false)
  const [atStep, setAtStep] = useState(1)

  const sendOrder = async () => {
    setIsLoading(true)
    const orderId = localStorage.getItem('checkoutOrder') || ''
    const cartItems = filteredProducts.map(product => {
      return { product_id: product.id, quantity: parseInt(product.quantity) }
    })

    sendPurchase(orderId, { cartItems })
      .then(order => {
        setHasError(false)
        setAtStep(2)
        localStorage.setItem('checkoutOrder', order.order_id)
        renderKlarnaSnippet('klarna-checkout-container', order)
      })
      .catch(error => {
        console.error(error)
        setHasError(true)
      })
      .finally(() => {
        setIsLoading(false)
      })
  }

  return (
    <StyledCheckout>
      <Container narrowest>
        <PageContent>
          <Heading size={1} type={'h1'}>
            {getTranslation('checkoutHeader')}
          </Heading>
          <Divider size={SPACING.MEDIUM} line={COLORS.PRIMARY_DARK_BLUE} />

          {atStep === 1 && (
            <>
              <Content>
                <Heading size={3} type={'h3'}>
                  {getTranslation('checkoutStepOneHeader')}
                </Heading>
                <Text>{getTranslation('checkoutStepOneText')}</Text>
              </Content>
              <Divider size={SPACING.MEDIUM} line={COLORS.PRIMARY_DARK_BLUE} />
              <Cart inCheckout={true} />
              <Divider size={SPACING.MEDIUM} />

              <Row justify={'flex-end'}>
                {isLoading && <Loader />}
                <Button
                  textColor={COLORS.SECONDARY_LIGHT_BLUE}
                  icon={'arrow_forward'}
                  disabled={!filteredProducts.length || isLoading}
                  handleClick={sendOrder}
                >
                  {getTranslation('checkoutNextText')}
                </Button>
              </Row>
            </>
          )}

          {hasError && (
            <Content>
              <Divider size={SPACING.SMALL} />
              <StyledCheckout.Error>
                {getTranslation('checkoutErrorMessage')}
              </StyledCheckout.Error>
            </Content>
          )}

          <div id={'klarna-checkout-container'} />
        </PageContent>
        <Divider size={SPACING.LARGE} />
      </Container>
    </StyledCheckout>
  )
}

export { Checkout }
